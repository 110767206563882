<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <b-card>
      <b-row>
        <table class="table">
          <thead>
            <th>
              Client Details
            </th>
          </thead>
        </table>
      </b-row>

      <validation-observer ref="customerRules">
        <b-form>
          <b-row class="mt-2">
            <b-col cols="12" sm="6" md="4" xl="4">
              <b-form-group>
                <label for="firstName">Name*</label>
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-input id="firstName" v-model="clientData.name" :state="errors.length > 0 ? false : null" type="text" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="4">
              <b-form-group>
                <label for="email">Email</label>
                <b-form-input id="email" v-model="clientData.email" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="4">
              <b-form-group>
                <label for="address">Address</label>
                <b-form-input id="address" v-model="clientData.address" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="12">
              <b-form-group>
                <label for="note">Notes</label>

                <b-form-textarea id="textarea" v-model="clientData.note" placeholder="Enter something..." rows="3" max-rows="6"></b-form-textarea>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button v-if="id == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                Save
              </b-button>
              <b-button v-else type="submit" @click.prevent="formSubmitted" v-show="clientData.archived == false" variant="primary" class="mr-1 float-right">
                Save Changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card v-if="id != null">
      <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Project Reports</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <b-row>
        <b-col cols="12" xl="6" lg="6" md="6" sm="12">
          <b-alert variant="secondary" class="mt-1" show>
            <div class="alert-body">
              <span class="text-center h5"><strong>Total Duration: </strong> {{ hoursFormat(totalClient) }}</span>
            </div>
          </b-alert>
        </b-col>

        <b-col cols="12" xl="3" lg="3" md="3" sm="12" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 ">
          <treeselect value-consists-of="LEAF_PRIORITY" :multiple="true" :options="projects" :clearable="false" v-model="projectOption" valueFormat="object" :limit="2" @close="getByIdClient" class="per-page-selector d-inline-block w-100" placeholder="Project Filter" />
        </b-col>

        <b-col cols="12" xl="3" md="3" sm="12">
          <b-form-group label="Data Range" label-for="Data Range">
            <input class="dataRange" type="text" ref="flatpickrInput" :value="rangeDate" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" xl="6" md="6" sm="12">
          <!-- Vehiclle Image and Name -->
          <b-card-body v-if="summaryReports.length > 0">
            <div>
              <canvas ref="chart"></canvas>
            </div>
          </b-card-body>
        </b-col>

        <b-col cols="12" xl="6" md="6" sm="12">
          <!-- Vehiclle Image and Name -->
          <b-card-body v-if="summaryReports.length > 0">
            <div>
              <canvas ref="chartPie"></canvas>
            </div>
          </b-card-body>
        </b-col>
      </b-row>

      <hr />

      <b-row class="mb-5" v-if="summaryReports.length > 0">
        <b-col cols="12" xl="12" md="12">
          <!-- Vehiclle Image and Name -->
          <b-card-body>
            <app-collapse type="margin">
              <app-collapse-item class="overflow-auto" :id="'collapse -'" :title="employee.name + '  -  ' + hoursFormat(employee.duration)" v-for="employee in summaryReports" :key="employee._id">
                <b-table v-if="employee.entries.length > 0" striped hover ref="refUserListTable" class="position-relative" :items="employee.entries" filter-debounce="250" responsive :small="true" head-variant="dark" :fields="tableColumns" show-empty empty-text="No matching records found">
                  <template #cell(date)="data">
                    <small>{{ dateFormat(data.item.timeInterval.start) }}</small>
                    <br />

                    <small>{{ dateFormat(data.item.timeInterval.end) }}</small>
                  </template>

                  <template #cell(clientName)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(projectName)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(description)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(timeInterval)="data">
                    {{ hoursFormat(data.value.duration) }}
                  </template>
                  <template #cell(billable)="data">
                    <b-badge pill v-if="data.value" variant="success">Billable</b-badge>
                    <b-badge pill v-else variant="secondary"> Non Billable </b-badge>
                  </template>
                  <template #cell(tags)="data">
                    <b-badge pill v-for="tag in data.value" :key="tag.index" small variant="info">{{ tag.name }}</b-badge>
                  </template>
                </b-table>
              </app-collapse-item>
            </app-collapse>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import { BForm, BCard, BContainer, BFormFile, BTabs, BTab, BBadge, BListGroupItem, BListGroup, BNavItem, BFormTextarea, BFormRadioGroup, BInputGroup, BFormRadio, BAlert, BButton, BOverlay, BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import vSelect from 'vue-select';
import { onUnmounted } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import VueNumericInput from 'vue-numeric-input';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import { required, email } from '@validations';
import router from '@/router';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import moment from 'moment';
import axiosIns from '@/libs/axios';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import Chart from 'chart.js';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: {
    BCard,
    BForm,
    Cleave,

    BContainer,
    BFormFile,
    BTabs,
    BTab,
    flatPickr,
    BBadge,
    BListGroupItem,
    BListGroup,
    BNavItem,
    BCardActions,
    BFormTextarea,
    BFormRadioGroup,
    BInputGroup,
    BFormRadio,
    BAlert,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,
    ToastificationContent,
    VueNumericInput,
    Treeselect,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    Ripple,
  },

  computed: {},

  created() {
    const today = this.getTodayDate();
    const yesterday = this.getYesterdayDate();
    this.rangeDate = String(yesterday) + ' to ' + String(today);

    this.getByIdClient();
  },

  data() {
    var clientDataModel = {
      name: null,
      email: null,
      address: null,
      note: null,
      archived: false,
    };

    return {
      summaryReports: [],
      detailedReports: [],
      projectsList: [],
      clientList: [],
      projectOption: [],
      projects: [],
      totalClient: 0,

      totalTime: 0,
      totalEntries: 0,
      colours: ['#0c1797D0', '#64e0f3D0', '#f90d1dD0', '#6c8b6cD0', '#e2d810D0', '#ff7b00D0', '#b31cd4D0', '#2dcca7D0', '#d6008aD0', '#5b5b5bD0'],

      rangeDate: null,

      options: {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+63',
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },

      id: null,
      formShow: false,
      clientData: clientDataModel,
      required,
      email,

      tableColumns: [
        { key: 'date', label: 'Date', class: 'text-center' },
        { key: 'clientName', label: 'Client', class: 'text-center' },

        { key: 'projectName', label: 'Project', class: 'text-center' },
        { key: 'description', label: 'Description', class: 'text-center' },

        { key: 'timeInterval', label: 'Duration', class: 'text-center' },
        { key: 'billable', label: 'Billable', class: 'text-center' },
        { key: 'tags', label: 'Tags', class: 'text-center' },
      ],

      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        legend: {
          display: true,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
              scaleLabel: {
                display: true,
                labelString: 'Duration (hours)',
              },
            },
          ],
        },
      },
      chartDataPie: {
        labels: [],
        datasets: [],
      },
      chartOptionsPie: {
        responsive: true,
        maintainAspectRatio: true,
      },
    };
  },

  mounted() {
    if (router.currentRoute.params.id) {
      flatpickr(this.$refs.flatpickrInput, {
        dateFormat: 'd-m-Y',
        mode: 'range',
        dateFormat: 'd-m-Y',
        locale: {
          firstDayOfWeek: 1,
        },
        onClose: (selectedDates, dateStr, instance) => {
          if (!instance.isOpen) {
            this.rangeDate = dateStr;
            this.getByIdClient();
          }
        },
      });
    }
  },

  methods: {
    getTodayDate() {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Note: January is 0!
      const year = today.getFullYear();
      const todayDate = `${day}-${month}-${year}`;
      return todayDate;
    },

    getYesterdayDate() {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const day = yesterday.toLocaleDateString('en-GB', options).slice(0, 2);
      const month = yesterday.toLocaleDateString('en-GB', options).slice(3, 5);
      const year = yesterday.toLocaleDateString('en-GB', options).slice(6);
      const yesterdayFormatted = `${day}-${month}-${year}`;
      return yesterdayFormatted;
    },

    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format('DD/MM/YYYY hh:mm');
      }
    },

    formSubmitted() {
      this.formShow = true;

      this.$refs.customerRules.validate().then((success) => {
        if (success) {
          if (router.currentRoute.params.id) {
            axiosIns
              .put('https://api.clockify.me/api/v1/workspaces/600ab5ea69564b6c1564e1fe/clients/' + router.currentRoute.params.id, this.clientData, {
                headers: {
                  'Content-Type': 'application/json',
                  'X-Api-Key': 'OGM5M2IyY2EtNGJlYS00ZGZiLWEwZGEtODMxNWUwN2RmYWNk',
                },
              })
              .then((response) => {
                this.formShow = false;
              })
              .catch((error) => {
                console.log(error);
                this.formShow = false;
              });
          } else {
            var tempData = {
              address: this.clientData.address,
              email: this.clientData.email,
              name: this.clientData.name,
              note: this.clientData.note,
            };

            axiosIns
              .post('https://api.clockify.me/api/v1/workspaces/600ab5ea69564b6c1564e1fe/clients', tempData, {
                headers: {
                  'Content-Type': 'application/json',
                  'X-Api-Key': 'OGM5M2IyY2EtNGJlYS00ZGZiLWEwZGEtODMxNWUwN2RmYWNk',
                },
              })
              .then((response) => {
                if (response.status == 201) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ Customer Insert Successful',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });

                  this.formShow = false;
                }
              });
          }
        } else {
          this.formShow = false;
        }
      });
    },

    dateFormat(val) {
      return moment(String(val)).format('DD-MM-YYYY HH:mm');
    },

    convertToISO(dateString) {
      // const parts = dateString.split('-');
      // const isoDate = new Date(parts[2], parts[1] - 1, parts[0]).toISOString();

      const parts = dateString.split('-');
      const isoDate = new Date(Date.UTC(parts[2], parts[1] - 1, parts[0], 0, 0, 0)).toISOString();

      return isoDate;
    },

    generateRandomColor() {
      var tempColors = ['#' + Math.floor(Math.random() * 16777215).toString(16)];

      while (tempColors.length < 10) {
        var maxDist = -1;
        var maxColor = null;

        for (var i = 0; i < 100; i++) {
          var color = '#' + Math.floor(Math.random() * 16777215).toString(16);

          var minDist = Infinity;
          for (var j = 0; j < tempColors.length; j++) {
            var dist = chroma.deltaE(color, tempColors[j]);
            minDist = Math.min(minDist, dist);
          }

          if (minDist > maxDist) {
            maxDist = minDist;
            maxColor = color;
          }
        }

        tempColors.push(maxColor);
      }

      this.colours = tempColors;

      return tempColors;
    },

    hoursFormat(val) {
      var totalMinutes = Math.floor(val / 60);

      var hours = Math.floor(totalMinutes / 60);
      var minutes = totalMinutes % 60;

      return hours + ' Hours, ' + minutes + ' Minutes';
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    getByIdClient() {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        this.id = router.currentRoute.params.id;

        axiosIns
          .get('https://api.clockify.me/api/v1/workspaces/600ab5ea69564b6c1564e1fe/clients/' + router.currentRoute.params.id, {
            headers: {
              'Content-Type': 'application/json',
              'X-Api-Key': 'OGM5M2IyY2EtNGJlYS00ZGZiLWEwZGEtODMxNWUwN2RmYWNk',
            },
          })
          .then((response) => {
            this.clientData = response.data;
            this.formShow = false;
          })
          .catch((error) => {
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
          });

        if (this.projectOption.length > 0) {
          var projectIds = this.projectOption.map((a) => a.id);

          var tempData = {
            detailedFilter: {
              groups: ['USER'],
              sortColumn: 'USER',
              pageSize: 1000,
              page: 0,
            },
            clients: { ids: [this.id] },
            dateRangeStart: null,
            dateRangeEnd: null,
            amounts: [],
            projects: {
              contains: 'CONTAINS',
              ids: projectIds,
            },
          };

          var summaryData = {
            summaryFilter: {
              groups: ['USER'],
              sortColumn: 'GROUP',
              pageSize: 1000,
              page: 0,
            },
            dateRangeStart: null,
            dateRangeEnd: null,
            amounts: [],
            clients: { ids: [this.id] },
            projects: {
              contains: 'CONTAINS',
              ids: projectIds,
            },
          };
        } else {
          var tempData = {
            detailedFilter: {
              groups: ['USER'],
              sortColumn: 'USER',
              pageSize: 1000,
              page: 0,
            },
            clients: { ids: [this.id] },
            dateRangeStart: null,
            dateRangeEnd: null,
            amounts: [],
          };

          var summaryData = {
            summaryFilter: {
              groups: ['USER'],
              sortColumn: 'GROUP',
              pageSize: 1000,
              page: 0,
            },
            dateRangeStart: null,
            dateRangeEnd: null,
            amounts: [],
            clients: { ids: [this.id] },
          };
        }

        if (this.rangeDate.includes('to')) {
          var dateRangeStart = this.rangeDate.split(' to ')[0];
          var dateRangeEnd = this.rangeDate.split(' to ')[1];

          tempData.dateRangeStart = this.convertToISO(dateRangeStart);
          tempData.dateRangeEnd = this.convertToISO(dateRangeEnd);

          summaryData.dateRangeStart = this.convertToISO(dateRangeStart);
          summaryData.dateRangeEnd = this.convertToISO(dateRangeEnd);
        } else {
          const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

          const parts = this.rangeDate.split('-');
          const date = new Date(parts[2], parts[1] - 1, parts[0]);
          date.setDate(date.getDate() + 1);
          const day = date.toLocaleDateString('en-GB', options).slice(0, 2);
          const month = date.toLocaleDateString('en-GB', options).slice(3, 5);
          const year = date.toLocaleDateString('en-GB', options).slice(6);
          const tomorrowFormatted = `${day}-${month}-${year}`;

          tempData.dateRangeStart = this.convertToISO(this.rangeDate);
          summaryData.dateRangeStart = this.convertToISO(this.rangeDate);

          tempData.dateRangeEnd = this.convertToISO(tomorrowFormatted);
          summaryData.dateRangeEnd = this.convertToISO(tomorrowFormatted);
        }
        this.detailedReports = [];
        this.summaryReports = [];
        this.chartData.datasets = [];
        this.chartData.labels = [];

        axiosIns
          .post('https://reports.api.clockify.me/v1/workspaces/600ab5ea69564b6c1564e1fe/reports/summary', summaryData, {
            headers: {
              'Content-Type': 'application/json',
              'X-Api-Key': 'OGM5M2IyY2EtNGJlYS00ZGZiLWEwZGEtODMxNWUwN2RmYWNk',
            },
          })
          .then((response) => {
            if (response.data.totals[0] != null) {
              this.totalTime = response.data.totals[0].totalTime ?? 0;
              this.totalEntries = response.data.totals[0].entriesCount ?? 0;
            } else {
              this.totalTime = 0;
              this.totalEntries = 0;
            }

            response.data.groupOne.forEach((element, index) => {
              element.entries = [];
              element.color = this.colours[index];
            });

            this.summaryReports = response.data.groupOne;

            axiosIns
              .post('https://reports.api.clockify.me/v1/workspaces/600ab5ea69564b6c1564e1fe/reports/detailed', tempData, {
                headers: {
                  'Content-Type': 'application/json',
                  'X-Api-Key': 'OGM5M2IyY2EtNGJlYS00ZGZiLWEwZGEtODMxNWUwN2RmYWNk',
                },
              })
              .then((response) => {
                if (response.data.totals[0] != null) {
                  this.detailedReports = response.data.timeentries;
                  this.totalClient = response.data.totals[0].totalTime;
                } else {
                  this.detailedReports = [];
                  this.totalClient = 0;
                }

                var projects = [];

                if (this.projectOption.length == 0) {
                  var projectsList = [];
                  var clientsList = [];
                }

                var people = [];
                var bgcPeople = [];
                var timePeople = [];
                this.detailedReports.forEach((e) => {
                  projects.push(e.projectName);
                  if (this.projectOption.length == 0) {
                    projectsList.push({ label: e.projectName, id: e.projectId, value: e.projectId, clientId: e.clientId });
                    clientsList.push({ label: e.clientName, id: e.clientId, value: e.clientId });
                  }
                });

                var projectsUnique = [...new Set(projects)];
                var datasetsTemp = [];

                this.summaryReports.forEach((element) => {
                  people.push(element.name);
                  bgcPeople.push(element.color);

                  const decimalHours = element.duration / 3600;
                  const hours = Math.floor(decimalHours);
                  const minutes = Math.round((decimalHours - hours) * 60);
                  const hoursAndMinutes = `${hours}.${minutes}`;

                  timePeople.push(parseFloat(hoursAndMinutes));

                  element.entries = this.detailedReports.filter(function(el) {
                    return el.userId == element._id;
                  });

                  var times = [];

                  projectsUnique.forEach((projectName) => {
                    var time = 0;
                    element.entries.forEach((task) => {
                      if (task.projectName == projectName) {
                        time += task.timeInterval.duration;
                      }
                    });
                    const decimalHours = time / 3600;
                    const hours = Math.floor(decimalHours);
                    const minutes = Math.round((decimalHours - hours) * 60);
                    const hoursAndMinutes = `${hours}.${minutes}`;
                    times.push(parseFloat(hoursAndMinutes));
                  });

                  let x = {
                    label: element.name,
                    backgroundColor: element.color,
                    data: times,
                  };

                  datasetsTemp.push(x);
                });

                if (this.projectOption.length == 0) {
                  this.projectsList = [];
                  this.clientsList = [];
                  this.projects = [];
                  this.projectsList = projectsList.filter((v, i, a) => a.findIndex((v2) => ['name', 'id'].every((k) => v2[k] === v[k])) === i);
                  this.clientsList = clientsList.filter((v, i, a) => a.findIndex((v2) => ['name', 'id'].every((k) => v2[k] === v[k])) === i);
                  this.clientsList.forEach((element) => {
                    var newProject = this.projectsList.filter(function(el) {
                      return el.clientId == element.id;
                    });

                    this.projects.push({
                      id: element.id,
                      label: element.label ? element.label : 'No Client',
                      children: newProject,
                    });
                  });
                }

                this.chartDataPie.labels = [];
                this.chartDataPie.datasets = [];
                this.chartData.labels = [];
                this.chartData.datasets = [];
                this.chartData.labels = [...new Set(projectsUnique)];
                this.chartData.datasets = datasetsTemp;
                this.chartDataPie.labels = [...new Set(people)];
                this.chartDataPie.datasets = [
                  {
                    label: 'Minutes',
                    backgroundColor: bgcPeople,
                    data: timePeople,
                  },
                ];

                setTimeout(() => {
                  if (this.chartData.datasets.length > 0) {
                    var ctx = this.$refs.chart.getContext('2d');
                    var chart = new Chart(ctx, {
                      type: 'bar',
                      data: this.chartData,
                      options: this.chartOptions,
                    });
                  }
                }, 200);

                setTimeout(() => {
                  if (this.chartDataPie.labels.length > 0) {
                    var ctx = this.$refs.chartPie.getContext('2d');
                    var chartPie = new Chart(ctx, {
                      type: 'pie',
                      data: this.chartDataPie,
                      options: this.chartOptionsPie,
                    });
                  }
                }, 200);
              })
              .catch((error) => {
                console.log(error);
                this.formShow = false;
              });
          })
          .catch((error) => {
            console.log(error);
            this.formShow = false;
          });

        this.formShow = false;
      } else {
        this.formShow = false;
      }
    },

    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },

    validationForm1() {
      return new Promise((resolve, reject) => {
        this.$refs.form1.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.user-badge {
  width: 50%;
  align-content: center;
}

.dataRange {
  width: 90%;
  padding: 0.375rem 0rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  text-align: center;
}
</style>
